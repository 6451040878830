import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "Somos expertos en",
    x: { mobile: 55, tablet: 55, desktop: 55}, 
    y: { mobile:  8 , tablet:  8 , desktop: 8 },
    fontSize: { mobile: 75, tablet: 75, desktop: 75},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-thin text-white',
        tablet: 'font-now-thin text-white',
        desktop: 'font-now-thin text-white'
      }
    }
  },
  frase2: { 
    text: "lentes progresivos",
    x: { mobile: 105, tablet: 105, desktop: 55}, 
    y: { mobile:  17 , tablet:  17 , desktop: 16 },
    fontSize: { mobile: 95, tablet: 95, desktop: 80},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase3: { 
    text: "MONOFOCAL",
    x: { mobile: 105, tablet: 105, desktop: 62}, 
    y: { mobile:  17 , tablet:  17 , desktop: 31 },
    fontSize: { mobile: 95, tablet: 95, desktop: 45},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase4: { 
    text: "BIFOCAL",
    x: { mobile: 105, tablet: 105, desktop: 96}, 
    y: { mobile:  17 , tablet:  17 , desktop: 31 },
    fontSize: { mobile: 95, tablet: 95, desktop: 45},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase5: { 
    text: "MULTIFOCAL",
    x: { mobile: 105, tablet: 105, desktop: 126}, 
    y: { mobile:  17 , tablet:  17 , desktop: 31 },
    fontSize: { mobile: 95, tablet: 95, desktop: 45},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase6: { 
    imgSrc: '/Progresivos/Segundo/LenteCero.webp',
    x: { mobile: 105, tablet: 105, desktop: 54},
    y: { mobile: 30, tablet: 30, desktop:  39 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 530 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 410 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        desktop: ''
      }
    }
  },
  frase7: { 
    imgSrc: '/Progresivos/Segundo/LenteUno.webp',
    x: { mobile: 105, tablet: 105, desktop: 85},
    y: { mobile: 30, tablet: 30, desktop:  38 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 535 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 440 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        desktop: ''
      }
    }
  },
  frase8: { 
    imgSrc: '/Progresivos/Segundo/LenteDos.webp',
    x: { mobile: 105, tablet: 105, desktop: 117},
    y: { mobile: 30, tablet: 30, desktop:  38 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 570 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 440 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        desktop: ''
      }
    }
  },
  frase9: { 
    text: "LEJOS Ó CERCA",
    x: { mobile: 105, tablet: 105, desktop: 62.7}, 
    y: { mobile:  17 , tablet:  17 , desktop: 71 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase10: { 
    text: "LEJOS",
    x: { mobile: 105, tablet: 105, desktop: 97.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 47 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase11: { 
    text: "CERCA",
    x: { mobile: 105, tablet: 105, desktop: 97.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 71 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase12: { 
    text: "LEJOS",
    x: { mobile: 105, tablet: 105, desktop: 130}, 
    y: { mobile:  17 , tablet:  17 , desktop: 43 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase13: { 
    text: "INTERMEDIA",
    x: { mobile: 105, tablet: 105, desktop: 128}, 
    y: { mobile:  17 , tablet:  17 , desktop: 57 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase14: { 
    text: "CERCA",
    x: { mobile: 105, tablet: 105, desktop: 130.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 71 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
    
};


export const SegundoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/Segundo/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}
