import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 390, tablet: 390, desktop: 390 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: {
        text: " Elimina las migrañas", 
        x: { mobile:  123.5, tablet:  123.5, desktop: 123.5}, 
        y: { mobile: 20, tablet: 20, desktop:  20}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase3: {
        text: "activadas por la luz azul", 
        x: { mobile: 120, tablet: 120, desktop: 120}, 
        y: { mobile: 25, tablet: 25, desktop:  25}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase4: { 
        imgSrc: '/BlueClarity/ChicaEs/chico.webp',
        x: { mobile: 142, tablet: 142, desktop: 142},
        y: { mobile: 18, tablet: 18, desktop:  18 }, 
        imgWidth: { mobile: 90, tablet: 90, desktop: 90 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase5: {
        text: "Disminuye la fatiga", 
        x: { mobile: 124, tablet: 124, desktop: 124}, 
        y: { mobile: 35, tablet: 35, desktop:  35}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase6: {
        text: "visual", 
        x: { mobile: 135, tablet: 135, desktop: 135}, 
        y: { mobile: 40, tablet: 40, desktop:  40}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase7: { 
        imgSrc: '/BlueClarity/ChicaEs/ojo.webp',
        x: { mobile: 142, tablet: 142, desktop: 142},
        y: { mobile: 33, tablet: 33, desktop:  33 }, 
        imgWidth: { mobile: 90, tablet: 90, desktop: 90 },
        imgHeight: { mobile: 65, tablet: 65, desktop: 65 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase8: {
        text: "Brinda mejor calidad", 
        x: { mobile: 122, tablet: 122, desktop: 122}, 
        y: { mobile: 50, tablet: 50, desktop:  50}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase9: {
        text: " de sueño", 
        x: { mobile: 132, tablet: 132, desktop: 132}, 
        y: { mobile: 55, tablet: 55, desktop:  55}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase10: { 
        imgSrc: '/BlueClarity/ChicaEs/zzz.webp',
        x: { mobile: 142, tablet: 142, desktop: 142},
        y: { mobile: 47, tablet: 47, desktop:  47 }, 
        imgWidth: { mobile: 90, tablet: 90, desktop: 90 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase11: {
        text: "Calidad y durabilidad", 
        x: { mobile: 121, tablet: 121, desktop: 121}, 
        y: { mobile: 68, tablet: 68, desktop:  68}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase12: { 
        imgSrc: '/BlueClarity/ChicaEs/seguridad.webp',
        x: { mobile: 142, tablet: 142, desktop: 142},
        y: { mobile: 63, tablet: 63, desktop:  63 }, 
        imgWidth: { mobile: 80, tablet: 80, desktop: 80 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase13: {
        text: "Protección real contra", 
        x: { mobile: 121, tablet: 121, desktop: 121}, 
        y: { mobile: 78, tablet: 78, desktop:  78}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase14: {
        text: "la luz azul nociva", 
        x: { mobile: 125.5, tablet: 125.5, desktop: 125.5}, 
        y: { mobile: 82, tablet: 82, desktop:  82}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase15: { 
        imgSrc: '/BlueClarity/ChicaEs/tel.webp',
        x: { mobile: 142, tablet: 142, desktop: 142},
        y: { mobile: 77, tablet: 77, desktop:  77 }, 
        imgWidth: { mobile: 90, tablet: 90, desktop: 90 },
        imgHeight: { mobile: 65, tablet: 65, desktop: 65 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase16: {
        text: "Protección contra", 
        x: { mobile: 125, tablet: 125, desktop: 125}, 
        y: { mobile: 90, tablet: 90, desktop:  90}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase17: {
        text: "Los rayos uv", 
        x: { mobile: 130, tablet: 130, desktop: 130}, 
        y: { mobile: 94, tablet: 94, desktop:  94}, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase18: { 
        imgSrc: '/BlueClarity/ChicaEs/sun.webp',
        x: { mobile: 142, tablet: 142, desktop: 142},
        y: { mobile: 87, tablet: 87, desktop:  87 }, 
        imgWidth: { mobile: 90, tablet: 90, desktop: 90 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
};


function ChicaEs() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/ChicaEs/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default ChicaEs;