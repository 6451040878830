import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "ANTES",
    x: { mobile: 105, tablet: 105, desktop: 70}, 
    y: { mobile:  17 , tablet:  17 , desktop: 15 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase2: { 
    text: "AHORA",
    x: { mobile: 105, tablet: 105, desktop: 120}, 
    y: { mobile:  17 , tablet:  17 , desktop: 15 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase3: { 
    text: "TALLADO TRADICIONAL",
    x: { mobile: 105, tablet: 105, desktop: 63}, 
    y: { mobile:  17 , tablet:  17 , desktop: 20 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase4: { 
    text: "TALLADO DIGITAL",
    x: { mobile: 105, tablet: 105, desktop: 116}, 
    y: { mobile:  17 , tablet:  17 , desktop: 20 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },

  frase5: { 
    text: "Solo superficies esféricas",
    x: { mobile: 105, tablet: 105, desktop: 62.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 42 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase6: { 
    text: "Superficies complejas",
    x: { mobile: 105, tablet: 105, desktop: 114}, 
    y: { mobile:  17 , tablet:  17 , desktop: 41 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase7: { 
    text: "Estandarización",
    x: { mobile: 105, tablet: 105, desktop: 65.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase8: { 
    text: "Personalización",
    x: { mobile: 105, tablet: 105, desktop: 116.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 59 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase9: { 
    text: "Molestias",
    x: { mobile: 105, tablet: 105, desktop: 68}, 
    y: { mobile:  17 , tablet:  17 , desktop: 76 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase10: { 
    text: "Comodidad",
    x: { mobile: 105, tablet: 105, desktop: 117.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 76 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase11: { 
    text: "EVOLUCION",
    x: { mobile: 105, tablet: 105, desktop: 95}, 
    y: { mobile:  17 , tablet:  17 , desktop: 53 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase12: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 98 }, 
    y: { mobile: 17, tablet: 17, desktop: 20 },
    fontSize: { mobile: 95, tablet: 95, desktop: 400 },
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-[#DBFE00] ',
        tablet: 'font-now text-[#DBFE00] ',
        desktop: 'font-now text-[#DBFE00] ',
      }
    }
  },


  frase13: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 98}, 
    y: { mobile:  17 , tablet:  17 , desktop: 67 },
    fontSize: { mobile: 95, tablet: 95, desktop: 400},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-[#DBFE00]',
        tablet: 'font-now text-[#DBFE00]',
        desktop: 'font-now text-[#DBFE00]'
      }
    }
  },
  frase14: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 62}, 
    y: { mobile:  17 , tablet:  17 , desktop: 19.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 70},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-[#DBFE00]',
        tablet: 'font-now text-[#DBFE00]',
        desktop: 'font-now text-[#DBFE00]'
      }
    }
  },
  frase15: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 115}, 
    y: { mobile:  17 , tablet:  17 , desktop: 19.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 70},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-[#DBFE00]',
        tablet: 'font-now text-[#DBFE00]',
        desktop: 'font-now text-[#DBFE00]'
      }
    }
  },
  frase16: { 
    imgSrc: '/Progresivos/Quinto/u.png',
    x: { mobile: 105, tablet: 105, desktop: 120},
    y: { mobile: 30, tablet: 30, desktop:  30 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 100 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 95 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase17: { 
    imgSrc: '/Progresivos/Quinto/d.png',
    x: { mobile: 105, tablet: 105, desktop: 69.7},
    y: { mobile: 30, tablet: 30, desktop: 47 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 95 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 95 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase18: { 
    imgSrc: '/Progresivos/Quinto/t.png',
    x: { mobile: 105, tablet: 105, desktop: 120.3},
    y: { mobile: 30, tablet: 30, desktop:  46 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 90 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 90 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase19: { 
    imgSrc: '/Progresivos/Quinto/cu.png',
    x: { mobile: 105, tablet: 105, desktop: 70},
    y: { mobile: 30, tablet: 30, desktop:  65 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 90 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 90 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase20: { 
    imgSrc: '/Progresivos/Quinto/ci.png',
    x: { mobile: 105, tablet: 105, desktop: 120.5},
    y: { mobile: 30, tablet: 30, desktop:  64 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 90 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 90 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase21: { 
    imgSrc: '/Progresivos/Quinto/s.png',
    x: { mobile: 105, tablet: 105, desktop: 70},
    y: { mobile: 30, tablet: 30, desktop:  30 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 100 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 100 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase22: { 
    imgSrc: '/Progresivos/Cuarto/Fle.webp',
    x: { mobile: 105, tablet: 105, desktop: 99},
    y: { mobile: 30, tablet: 30, desktop:  43 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 90 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 60 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
};


export const QuintoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlancoLogo.webp"
        isBackground={true}
      />
    </div>
  );
}
