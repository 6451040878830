import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "LENTES PROGRESIVOS HD",
    x: { mobile: 105, tablet: 105, desktop: 68}, 
    y: { mobile:  17 , tablet:  17 , desktop: 10 },
    fontSize: { mobile: 95, tablet: 95, desktop: 120},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase2: { 
    text: "DIGITAL HIGH PERFORMANCE OPTIMIZADO",
    x: { mobile: 105, tablet: 105, desktop: 76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 25 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase3: { 
    text: "DIGITAL HIGH PERFORMANCE MUNDO TÁCTIL",
    x: { mobile: 105, tablet: 105, desktop: 76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 35 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase4: { 
    text: "DIGITAL OPTIMIZADO",
    x: { mobile: 105, tablet: 105, desktop: 76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 45 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase5: { 
    text: "DIGITAL PRIMER USUARIO",
    x: { mobile: 105, tablet: 105, desktop:76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 55 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase6: { 
    text: "DIGITAL SENIOR",
    x: { mobile: 105, tablet: 105, desktop: 76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 65 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase7: { 
    text: "DIGITAL DRIVE",
    x: { mobile: 105, tablet: 105, desktop: 76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase8: { 
    text: "OCUPACIONAL HD",
    x: { mobile: 105, tablet: 105, desktop: 76}, 
    y: { mobile:  17 , tablet:  17 , desktop: 85 },
    fontSize: { mobile: 95, tablet: 95, desktop: 50},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase9: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 24.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 70},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
  frase10: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 34.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
  frase11: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 44.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
  frase12: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 54.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
  frase13: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 64.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
  frase14: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 74.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
  frase15: { 
    text: "|",
    x: { mobile: 105, tablet: 105, desktop: 74.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 84.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-[#DBFE00]',
        tablet: 'font-now-bold text-[#DBFE00]',
        desktop: 'font-now-bold text-[#DBFE00]'
      }
    }
  },
};


export const OctavoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlancoLogo.webp"
        isBackground={true}
      />
    </div>
  );
}
