import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/Progresivos/Primero/Lentes.webp',
        x: { mobile: 105, tablet: 105, desktop: 105},
        y: { mobile: 30, tablet: 30, desktop:  30 }, 
        imgWidth: { mobile: 700, tablet: 700, desktop: 700 },
        imgHeight: { mobile: 550, tablet: 550, desktop: 550 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
        text: "Somos expertos en",
        x: { mobile: 105, tablet: 105, desktop: 105}, 
        y: { mobile:  17 , tablet:  17 , desktop: 17 },
        fontSize: { mobile: 95, tablet: 95, desktop: 95},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase3: { 
        text: "lentes progresivos",
        x: { mobile: 106.5, tablet: 106.5, desktop: 106.5}, 
        y: { mobile:  25 , tablet:  25 , desktop: 25 },
        fontSize: { mobile: 95, tablet: 95, desktop: 95},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase4: { 
      text: "LEJOS",
      x: { mobile: 106.5, tablet: 106.5, desktop: 119}, 
      y: { mobile:  25 , tablet:  25 , desktop: 43 },
      fontSize: { mobile: 95, tablet: 95, desktop: 35},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
  },
  frase5: { 
    text: "INTERMEDIA",
    x: { mobile: 106.5, tablet: 106.5, desktop: 111}, 
    y: { mobile:  25 , tablet:  25 , desktop: 57 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase6: { 
    text: "CERCA",
    x: { mobile: 106.5, tablet: 106.5, desktop: 125}, 
    y: { mobile:  25 , tablet:  25 , desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase7: { 
    text: <a> OBTÉN UNA <b>VISIÓN CÓMODA</b></a>,
    x: { mobile: 106.5, tablet: 106.5, desktop: 113}, 
    y: { mobile:  25 , tablet:  25 , desktop: 90 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase8: { 
    text: "EN TODAS LAS DISTANCIAS",
    x: { mobile: 106.5, tablet: 106.5, desktop: 114.5}, 
    y: { mobile:  25 , tablet:  25 , desktop: 95 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
};


export const PrimeroProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/Primero/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}
