import React from 'react';
//Animacion
import AnimatedSection from '../shared/hooks/useAnimation';

//ParteInicial
import {PrimeroProgresivos} from './pages/Primero.progresivos';
import {SegundoProgresivos} from './pages/Segundo.progresivos';
import {TerceroProgresivos} from './pages/Tercero.progresivos';
import {CuartoProgresivos} from './pages/Cuarto.progresivos';
import {QuintoProgresivos} from './pages/Quinto.progresivos';
import {SextoProgresivos} from './pages/Sexto.progresivos';
import {SeptimoProgresivos} from './pages/Septimo.progresivos';
import {OctavoProgresivos} from './pages/Octavo.progresivos';
import {NovenoProgresivos} from './pages/Noveno.progresivo';
import {DecimoProgresivos} from './pages/Decimo.progresivos';
import Sucursales from '../Inicio/pages/Sucursales.inicio';

function Progresivos() {
    return (
        <div className="flex flex-col" style={{ overflowY: 'hidden', margin: 0, padding: 0 }}>
            <AnimatedSection>
                <div className="flex justify-center items-center h-screen" style={{ margin: 0, padding: 0 }}>
                    <div className="w-1/4 md:w-1/5 lg:w-1/6">
                        <img
                            src="/Progressive/Logo.webp"
                            alt="Logo Stop Age"
                            className="w-full h-auto object-contain"
                        />
                    </div>
                </div>
            </AnimatedSection>
            {[PrimeroProgresivos, SegundoProgresivos, TerceroProgresivos, CuartoProgresivos, QuintoProgresivos, SextoProgresivos, SeptimoProgresivos, OctavoProgresivos, NovenoProgresivos, Sucursales].map((Component, index) => (
                <AnimatedSection key={index}>
                    <div className="relative flex items-center justify-center"  style={{ margin: 0, padding: 0 }}>
                        <Component />
                    </div>
                </AnimatedSection>
            ))}
        </div>
    );
}
export default Progresivos;
