import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
      imgSrc: '/BlueClarity/Lentes3/Lentes.webp',
      x: { mobile: 80, tablet: 80, desktop: 80},
      y: { mobile: 6, tablet: 6, desktop:  6 }, 
      imgWidth: { mobile: 720, tablet: 720, desktop: 720 },
      imgHeight: { mobile: 890, tablet: 890, desktop: 890 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase3: {
      text: "SUPERFICIE ANTIRAYAS", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 26, tablet: 26, desktop: 26}, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase4: {
      text: "CAPA ANTIESTÁTICA", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 44, tablet: 44, desktop: 44}, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase5: {
      text: "Elimina suciedad y polvo.", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 49, tablet: 49, desktop: 49}, 
      fontSize: { mobile: 40, tablet: 40, desktop: 40},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase6: {
      text: "CAPA HIDROFÓBICA", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 59, tablet: 59, desktop: 59}, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase7: {
      text: "Y OLEO FÓBICA", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 64, tablet: 64, desktop: 64}, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase8: {
      text: "Repele agua y aceites.",
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 69, tablet: 69, desktop: 69}, 
      fontSize: { mobile: 40, tablet: 40, desktop: 40},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase9: {
      text: " CAPA ANTIREFLEJO", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 78, tablet: 78, desktop: 78}, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase10: {
      text: "Elimina reflejos molestos", 
      x: { mobile: 116, tablet: 116, desktop: 116}, 
      y: { mobile: 84, tablet: 84, desktop: 84}, 
      fontSize: { mobile: 40, tablet: 40, desktop: 40},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase11: {
      text: " Tiene las siguientes", 
      x: { mobile: 55, tablet: 55, desktop:55}, 
      y: { mobile: 50, tablet: 50, desktop: 50}, 
      fontSize: { mobile: 75, tablet: 75, desktop: 75},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase12: {
      text: "protecciones", 
      x: { mobile: 55, tablet: 55, desktop: 55}, 
      y: { mobile: 58, tablet: 58, desktop: 58}, 
      fontSize: { mobile: 75, tablet: 75, desktop: 75},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
};


function LentesTres() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/Lentes3/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default LentesTres;