import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';
import { table } from 'framer-motion/client';

const frases = {
    frase1: { 
      imgSrc: '/AilensComponents/Lentes/cuados.webp',
      x: { mobile: 78, tablet: 78, desktop: 78 },
      y: { mobile: 6, tablet: 6, desktop: 3 }, 
      imgWidth: { mobile: 245, tablet: 245, desktop: 245 },
      imgHeight: { mobile: 160, tablet: 160, desktop: 160 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            desktop: ''
            }
        }
    },
    frase3: { 
        text:  "Diseño digital hasta ahora", 
        x: { mobile: 63, tablet: 63, desktop: 63 }, 
        y: { mobile: 90, tablet: 90, desktop: 90 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-gray-600',
                tablet: 'font-now text-gray-600',
                desktop: 'font-now text-gray-600'
            }
        }
    },
    frase4: { 
        text: "Mayor amplitud", 
        x: { mobile: 95, tablet: 95, desktop: 95 }, 
        y: { mobile: 10, tablet: 10, desktop: 8}, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-gray-600',
                tablet: 'font-now-thin text-gray-600',
                desktop: 'font-now-thin text-gray-600'
            }
        }
    },
    frase5: { 
        text: "en campos de visión", 
        x: { mobile: 93, tablet: 93, desktop: 93 }, 
        y: { mobile: 18, tablet: 18, desktop: 14 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-gray-600',
                tablet: 'font-now-thin text-gray-600',
                desktop: 'font-now-thin text-gray-600'
            }
        }
    },
    frase6: { 
        imgSrc: '/AilensComponents/Lentes/DerechaLente.webp',
        x: { mobile: 60, tablet: 60, desktop: 60 }, 
        y: { mobile: 23, tablet: 23, desktop: 23 }, 
        imgWidth: { mobile: 730, tablet: 730, desktop: 730 },
        imgHeight: { mobile: 500, tablet: 500, desktop: 500 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            desktop: ''
            }
        }
    },
    frase7: { 
        imgSrc: '/AilensComponents/Lentes/IzquierdaLente.webp',
        x: { mobile: 105, tablet: 105, desktop: 105 },
        y: { mobile: 23, tablet: 23, desktop: 23 }, 
        imgWidth: { mobile: 750, tablet: 750, desktop: 750 },
        imgHeight: { mobile: 500, tablet: 500, desktop: 500 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
};

function Lentes() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/AiLensComponents/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Lentes;