import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/Owl/PrimeraParte/owlHD.webp',
        x: { mobile: 53, tablet: 53, desktop: 53 },
        y: { mobile: 13, tablet: 13, desktop:  13 }, 
        imgWidth: { mobile: 310, tablet: 310, desktop: 310 },
        imgHeight: { mobile: 160, tablet: 160, desktop: 160 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
      text: "LENTES CON", 
      x: { mobile: 62, tablet: 62, desktop: 62 }, 
      y: { mobile: 90, tablet: 90, desktop: 90 }, 
      fontSize: { mobile: 55, tablet: 55, desktop: 55},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase3: { 
      text: "TECNOLOGÍA DIGITAL", 
      style: "",
      x: { mobile: 78, tablet: 78, desktop: 78 }, 
      y: { mobile: 90, tablet: 90, desktop: 90 }, 
      fontSize: { mobile: 55, tablet: 55, desktop: 55},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]',
                tablet: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]',
                desktop: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]'
            }
        }
    },
    
    frase4: { 
      text: ", VISIÓN MÁS CLARA Y PRECISA", 
      x: { mobile: 104, tablet: 104, desktop: 104}, 
      y: { mobile: 90, tablet: 90, desktop: 90 },
      fontSize: { mobile: 55, tablet: 55, desktop: 55},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
};


function Buho() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/Buho/Buho.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Buho;
