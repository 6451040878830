import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/Owl/Chica/chica.webp',
        x: { mobile: 98, tablet: 98, desktop: 98},
        y: { mobile: 11, tablet: 11, desktop:  11 }, 
        imgWidth: { mobile: 1000, tablet: 1000, desktop: 1000 },
        imgHeight: { mobile: 860, tablet: 860, desktop: 860 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    }, 
    frase2: { 
        imgSrc: '/Owl/Chica/lente.webp',
        x: { mobile: 111, tablet: 111, desktop: 111 },
        y: { mobile: 16, tablet: 16, desktop:  16 }, 
        imgWidth: { mobile: 460, tablet: 460, desktop: 460 },
        imgHeight: { mobile: 280, tablet: 280, desktop:280 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase4: { 
        text: "TUS INICIALES",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 54, tablet: 54, desktop: 54 },
        fontSize: { mobile: 83, tablet: 83, desktop: 83},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase5: { 
        text: "GRABADAS CON", 
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 64, tablet: 64, desktop:64 },
        fontSize: { mobile: 83, tablet: 83, desktop: 83},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase6: { 
        text: "PUNTA DE DIAMANTES", 
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 74, tablet: 74, desktop:74},
        fontSize: { mobile: 83, tablet: 83, desktop: 83},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
};


function Ranita() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/Chica/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Ranita;