import React from 'react';
//Animacion
import AnimatedSection from '../shared/hooks/useAnimation';

//ParteInicial
import Chica from './pages/Chica.ailens';
import Lentes from './pages/Lentes.ailens';
import Chico from './pages/Chico.ailens';
import PerformanceNum from './pages/Performance.ailens';
import PerformancePor from './pages/PerformanceNumeros.ailens';
import Disponibilidad from './pages/Disponibildad.ailens';
import Sucursales from '../Inicio/pages/Sucursales.inicio';


function Ailens() {
    return (
        
        <div className="flex flex-col" style={{ overflowY: 'hidden', margin: 0, padding: 0 }}>
            <AnimatedSection>
                <div className="flex justify-center items-center h-screen" style={{ margin: 0, padding: 0 }}>
                    <div className="w-1/4 md:w-1/5 lg:w-1/6">
                        <img
                            src="/AilensComponents/Logo/Logo.webp"
                            alt="Logo Stop Age"
                            className="w-full h-auto object-contain"
                        />
                    </div>
                </div>
            </AnimatedSection>
            {[Chica, Lentes, Chico, PerformanceNum, PerformancePor, Disponibilidad, Sucursales].map((Component, index) => (
                <AnimatedSection key={index}>
                    <div className="relative flex items-center justify-center"  style={{ margin: 0, padding: 0 }}>
                        <Component />
                    </div>
                </AnimatedSection>
            ))}
        </div>
        
    );
}
export default Ailens;



