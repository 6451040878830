import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "TECNOLOGÍA Y CALIDAD",
    x: { mobile: 105, tablet: 105, desktop: 70}, 
    y: { mobile:  17 , tablet:  17 , desktop: 14 },
    fontSize: { mobile: 95, tablet: 95, desktop: 110},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase2: { 
    text: "80%",
    x: { mobile: 105, tablet: 105, desktop: 50 }, 
    y: { mobile: 17, tablet: 17, desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 230 },
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '', 
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now-bol text-white bg-gray-500 px-[20vh] py-2 transform scale-x-[1.15] rounded-r-[5vh]', // Desplazamiento a la derecha y escala del texto
      }
    }
  }
  ,
  frase3: { 
    text: "de nuestros block para",
    x: { mobile: 105, tablet: 105, desktop:100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 35 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase4: { 
    text: "fabricación de lentes provienen",
    x: { mobile: 105, tablet: 105, desktop: 100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase5: { 
    text: "de empresas mundiales",
    x: { mobile: 105, tablet: 105, desktop: 100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 45 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase6: { 
    text: " Productos provenientes de ",
    x: { mobile: 105, tablet: 105, desktop: 60}, 
    y: { mobile:  17 , tablet:  17 , desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 53},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase7: { 
    text: "Alemania y Estados Unidos",
    x: { mobile: 105, tablet: 105, desktop: 89.5}, 
    y: { mobile:  17 , tablet:  17 , desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 53},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase8: { 
    text: "principalmente.",
    x: { mobile: 105, tablet: 105, desktop: 120}, 
    y: { mobile:  17 , tablet:  17 , desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 57},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
};


export const SextoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlancoLogo.webp"
        isBackground={true}
      />
    </div>
  );
}
