import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/Owl/SegundaParte/lentes.webp',
        x: { mobile: 100, tablet: 100, desktop: 100 },
        y: { mobile: 25, tablet: 25, desktop:  25 }, 
        imgWidth: { mobile: 730, tablet: 730, desktop: 730 },
        imgHeight: { mobile: 500, tablet: 500, desktop: 500 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
        imgSrc: '/Owl/SegundaParte/Owl.webp',
        x: { mobile: 65, tablet: 65, desktop: 65 },
        y: { mobile: 18, tablet: 18, desktop:  18 }, 
        imgWidth: { mobile: 290, tablet: 290, desktop: 290 },
        imgHeight: { mobile: 190, tablet: 190, desktop: 190 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase3: { 
      text: "VISIÓN EN",
      x: { mobile: 57, tablet: 57, desktop: 57 }, 
      y: { mobile: 70, tablet: 70, desktop: 70 }, 
      fontSize: { mobile: 80, tablet: 80, desktop: 80},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-gray-600 [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]',
                tablet: 'font-now-bold text-gray-600 [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]',
                desktop: 'font-now-bold text-gray-600 [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]'
            }
        }
    },
    frase4: { 
      text: "ALTA DEFINICIÓN",
      x: { mobile: 57, tablet: 57, desktop: 57 }, 
      y: { mobile: 80, tablet: 80, desktop: 80 }, 
      fontSize: { mobile: 80, tablet: 80, desktop: 80},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-gray-600 [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]',
                tablet: 'font-now-bold text-gray-600 [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]',
                desktop: 'font-now-bold text-gray-600 [text-shadow:0_0_10px_rgba(255,255,255,0.8),0_0_20px_rgba(255,255,255,0.6),0_0_30px_rgba(255,255,255,0.4)]'
            }
        }
    },
    
    frase5: { 
      text: "Un antes y un después en la manera de VER las cosas",
      x: { mobile: 57, tablet: 57, desktop: 57}, 
      y: { mobile: 90, tablet: 90, desktop: 90 },
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-gray-600',
                tablet: 'font-now-bold text-gray-600',
                desktop: 'font-now-bold text-gray-600'
            }
        }
    },
    frase6: { 
        imgSrc: '/Owl/SegundaParte/televisor.webp',
        x: { mobile: 78, tablet: 78, desktop: 78 },
        y: { mobile: 44, tablet: 44, desktop:  44 }, 
        imgWidth: { mobile: 170, tablet: 170, desktop: 170 },
        imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase7: { 
        imgSrc: '/Owl/SegundaParte/tele.webp',
        x: { mobile: 58, tablet: 58, desktop: 58 },
        y: { mobile: 42, tablet: 42, desktop:  42 }, 
        imgWidth: { mobile: 150, tablet: 150, desktop: 150 },
        imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase8: { 
        imgSrc: '/Owl/SegundaParte/flechas.webp',
        x: { mobile: 70, tablet: 70, desktop: 70 },
        y: { mobile: 48, tablet: 48, desktop:  48 }, 
        imgWidth: { mobile: 70, tablet: 70, desktop: 70 },
        imgHeight: { mobile: 50, tablet: 50, desktop: 50 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
};


function Vision() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/SegundaParte/iconic.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Vision;
