import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "Personalización para cada",
    x: { mobile: 105, tablet: 105, desktop: 100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 15 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase2: { 
    text: "paciente y montura. Lentes",
    x: { mobile: 105, tablet: 105, desktop: 100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 17.4 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase3: { 
    text: "adaptados a la edad, a la ",
    x: { mobile: 105, tablet: 105, desktop: 100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 20.4 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase4: { 
    text: "necesidad y a la actividad",
    x: { mobile: 105, tablet: 105, desktop: 100}, 
    y: { mobile:  17 , tablet:  17 , desktop: 23.4 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase5: { 
    text: "No hay dos personas iguales,",
    x: { mobile: 105, tablet: 105, desktop: 94}, 
    y: { mobile:  17 , tablet:  17 , desktop: 35 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase6: { 
    text: "por eso no puede haber dos",
    x: { mobile: 105, tablet: 105, desktop: 94}, 
    y: { mobile:  17 , tablet:  17 , desktop: 37.4 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase7: { 
    text: "progresivos iguales.",
    x: { mobile: 105, tablet: 105, desktop: 94}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase8: { 
    text: "Fácil Adaptación: Es posible",
    x: { mobile: 105, tablet: 105, desktop: 88}, 
    y: { mobile:  17 , tablet:  17 , desktop: 55 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase9: { 
    text: "alcanzar porcentajes del",
    x: { mobile: 105, tablet: 105, desktop: 88}, 
    y: { mobile:  17 , tablet:  17 , desktop: 57.6 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase10: { 
    text: "100% y la transición entre",
    x: { mobile: 105, tablet: 105, desktop: 88}, 
    y: { mobile:  17 , tablet:  17 , desktop:60.4 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase11: { 
    text: "Se adaptan al trabajo,",
    x: { mobile: 105, tablet: 105, desktop: 83}, 
    y: { mobile:  17 , tablet:  17 , desktop: 72 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase12: { 
    text: "estética, gustos y estilo de",
    x: { mobile: 105, tablet: 105, desktop: 83}, 
    y: { mobile:  17 , tablet:  17 , desktop: 74.6 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase13: { 
    text: "vida. (necesidades diarias",
    x: { mobile: 105, tablet: 105, desktop: 83}, 
    y: { mobile:  17 , tablet:  17 , desktop: 77 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase14: { 
    text: "de cada usuario).",
    x: { mobile: 105, tablet: 105, desktop: 83}, 
    y: { mobile:  17 , tablet:  17 , desktop: 79.4 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase15: { 
    text: "¿QUÉ SIGNIFICA HD?",
    x: { mobile: 105, tablet: 105, desktop: 55}, 
    y: { mobile:  17 , tablet:  17 , desktop: 90 },
    fontSize: { mobile: 95, tablet: 95, desktop: 90},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase16: { 
    text: "1",
    x: { mobile: 105, tablet: 105, desktop: 120 }, 
    y: { mobile: 17, tablet: 17, desktop: 20 },
    fontSize: { mobile: 95, tablet: 95, desktop: 110 },
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold  bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        tablet: 'font-now-bold  bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        desktop: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full'
      }
    }
  },
  frase17: { 
    text: "2",
    x: { mobile: 105, tablet: 105, desktop: 114 }, 
    y: { mobile: 17, tablet: 17, desktop: 38 },
    fontSize: { mobile: 95, tablet: 95, desktop: 110},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        tablet: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        desktop: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full'
      }
    }
  },
  frase18: { 
    text: "3",
    x: { mobile: 105, tablet: 105, desktop: 107 }, 
    y: { mobile: 17, tablet: 17, desktop: 58 },
    fontSize: { mobile: 95, tablet: 95, desktop: 110},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        tablet: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        desktop: 'font-now-bold   bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full'
      }
    }
  },
  frase19: { 
    text: "4",
    x: { mobile: 105, tablet: 105, desktop: 100 }, 
    y: { mobile: 17, tablet: 17, desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 110},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold  bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        tablet: 'font-now-bold  bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full',
        desktop: 'font-now-bold  bg-white text-gray flex items-center justify-center w-24 h-24 rounded-full'
      }
    }
  },

};


export const SeptimoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlancoLogo.webp"
        isBackground={true}
      />
    </div>
  );
}
