import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/Owl/OwlHd/1.webp',
        x: { mobile: 52, tablet: 52, desktop: 52 },
        y: { mobile: 64, tablet: 64, desktop:  64 }, 
        imgWidth: { mobile: 640, tablet: 640, desktop: 640 },
        imgHeight: { mobile: 70, tablet: 70, desktop: 70 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
        imgSrc: '/Owl/OwlHd/2.webp',
        x: { mobile: 52, tablet: 52, desktop: 52 },
        y: { mobile: 75, tablet: 75, desktop:  75 }, 
        imgWidth: { mobile: 680, tablet: 680, desktop: 680 },
        imgHeight: { mobile: 70, tablet: 70, desktop: 70 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase3: { 
        imgSrc: '/Owl/OwlHd/3.webp',
        x: { mobile: 51.5, tablet: 51.5, desktop: 51.5 },
        y: { mobile: 87, tablet: 87, desktop:  87 }, 
        imgWidth: { mobile: 540, tablet: 540, desktop: 540 },
        imgHeight: { mobile: 70, tablet: 70, desktop: 70 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase4: { 
        imgSrc: '/Owl/OwlHd/4.webp',
        x: { mobile: 115, tablet: 115, desktop: 115 },
        y: { mobile: 11, tablet: 11, desktop:  11 }, 
        imgWidth: { mobile: 440, tablet: 440, desktop: 440 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase5: { 
        imgSrc: '/Owl/OwlHd/5.webp',
        x: { mobile: 90, tablet: 90, desktop: 90 },
        y: { mobile: 30, tablet: 30, desktop:  30 }, 
        imgWidth: { mobile: 1200, tablet: 1200, desktop: 1200 },
        imgHeight: { mobile: 460, tablet: 460, desktop: 460 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase6:{
        imgSrc: '/Owl/OwlHd/owlIcon.webp',
        x: { mobile: 60, tablet: 60, desktop: 60 },
        y: { mobile: 20, tablet: 20, desktop:  20 }, 
        imgWidth: { mobile: 460, tablet: 460, desktop: 460 },
        imgHeight: { mobile: 290, tablet: 290, desktop: 290 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    }
};


function OwlHd() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/OwlHd/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default OwlHd;
