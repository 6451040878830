import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: ['DISTANCIA/', 'MULTIFOCALES'],
    distanciaText: { mobile: -5, tablet: -10, desktop: -10},
    x: { mobile: 68, tablet: 68, desktop: 60}, 
    y: { mobile:  40 , tablet:  40 , desktop: 40 },
    fontSize: { mobile: 60, tablet: 60, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white ',
        tablet: 'font-now-bold text-white ',
        desktop: 'font-now-bold text-white '
      }
    }
  },
  frase2: { 
    text: ['DHP','OPTIMIZADO'],
    distanciaText: { mobile: -5, tablet: -10, desktop: -10},
    x: { mobile: 105, tablet: 105, desktop: 73}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase3: { 
    text: ['DHP','MUNDO','TÁCTIL'],
    distanciaText: { mobile: -5, tablet: -10, desktop: -10},
    x: { mobile: 105, tablet: 105, desktop: 84}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 30},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase4: { 
    text: "OPTIMIZADO",
    x: { mobile: 105, tablet: 105, desktop: 90}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase5: { 
    text: ['PRIMER', 'USUARIO'],
    distanciaText: { mobile: -5, tablet: -10, desktop: -10},
    x: { mobile: 105, tablet: 105, desktop:101}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase6: { 
    text: "SENIOR",
    x: { mobile: 105, tablet: 105, desktop:110}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase7: { 
    text: "DRIVE",
    x: { mobile: 105, tablet: 105, desktop: 117}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase8: { 
    text: ['OCUPACIONAL', 'DIGITAL'],
    distanciaText: { mobile: -5, tablet: -10, desktop: -10},
    x: { mobile: 105, tablet: 105, desktop: 123}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase9: { 
    text: "LEJOS",
    x: { mobile: 105, tablet: 105, desktop: 60}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase10: { 
    text: "INTERMEDIO",
    x: { mobile: 105, tablet: 105, desktop: 60}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase11: { 
    text: "INTERMEDIO",
    x: { mobile: 105, tablet: 105, desktop: 60}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase12: { 
    text: "★★★",
    x: { mobile: 105, tablet: 105, desktop: 74}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase13: { 
    text: "★★★",
    x: { mobile: 105, tablet: 105, desktop: 74}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase14: { 
    text: "★★★",
    x: { mobile: 105, tablet: 105, desktop: 74}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase15: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 84}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase16: { 
    text: "★★★",
    x: { mobile: 105, tablet: 105, desktop: 83}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase17: { 
    text: "★★★",
    x: { mobile: 105, tablet: 105, desktop: 83}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase18: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 93}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase19: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 93}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase20: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 93}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase21: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 102}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase22: { 
    text: "★",
    x: { mobile: 105, tablet: 105, desktop: 103}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase23: { 
    text: "★",
    x: { mobile: 105, tablet: 105, desktop: 103}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase24: { 
    text: "★",
    x: { mobile: 105, tablet: 105, desktop: 112}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase25: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 111}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase26: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 111}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase27: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 118}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase28: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 118}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase29: { 
    text: "-",
    x: { mobile: 105, tablet: 105, desktop: 119}, 
    y: { mobile:  17 , tablet:  17 , desktop: 69 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase30: { 
    text: "o",
    x: { mobile: 105, tablet: 105, desktop: 126}, 
    y: { mobile:  17 , tablet:  17 , desktop: 50 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase31: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 125}, 
    y: { mobile:  17 , tablet:  17 , desktop: 60 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase32: { 
    text: "★★",
    x: { mobile: 105, tablet: 105, desktop: 125}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 40},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase33: { 
    text: "LENTES PROGRESIVOS HD",
    x: { mobile: 105, tablet: 105, desktop: 68}, 
    y: { mobile:  17 , tablet:  17 , desktop: 10 },
    fontSize: { mobile: 95, tablet: 95, desktop: 120},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase34: { 
    text: "LENTES PROGRESIVOS HD",
    x: { mobile: 105, tablet: 105, desktop: 68}, 
    y: { mobile:  17 , tablet:  17 , desktop: 10 },
    fontSize: { mobile: 95, tablet: 95, desktop: 120},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase35: { 
    text: "LENTES PROGRESIVOS HD",
    x: { mobile: 105, tablet: 105, desktop: 68}, 
    y: { mobile:  17 , tablet:  17 , desktop: 10 },
    fontSize: { mobile: 95, tablet: 95, desktop: 120},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase36: { 
    text: "LENTES PROGRESIVOS HD",
    x: { mobile: 105, tablet: 105, desktop: 68}, 
    y: { mobile:  17 , tablet:  17 , desktop: 10 },
    fontSize: { mobile: 95, tablet: 95, desktop: 120},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
};


export const NovenoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlancoLogo.webp"
        isBackground={true}
      />
    </div>
  );
}
