import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56, tablet: 56, desktop: 56},
        y: { mobile: 12, tablet: 12, desktop:  12 }, 
        imgWidth: { mobile: 390, tablet: 390, desktop: 390 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    }, 
    frase2: { 
        text: "UN LENTE",
        x: { mobile: 57, tablet: 57, desktop: 57}, 
        y: { mobile: 84, tablet: 84, desktop: 84 },
        fontSize: { mobile: 39, tablet: 39, desktop: 39},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase3: { 
        text: "DISEÑADO PARA",
        x: { mobile: 57, tablet: 57, desktop: 57}, 
        y: { mobile: 89, tablet: 89, desktop: 89 },
        fontSize: { mobile: 39, tablet: 39, desktop: 39},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase4: { 
        text: "LA VIDA DIGITAL",
        x: { mobile: 57, tablet: 57, desktop: 57}, 
        y: { mobile: 95, tablet: 95, desktop: 95 },
        fontSize: { mobile: 59, tablet: 59, desktop: 59},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase5: { 
      imgSrc: '/BlueClarity/Chica/celus.png',
      x: { mobile: 71, tablet: 71, desktop: 71},
      y: { mobile: 81, tablet: 81, desktop:  81 }, 
      imgWidth: { mobile: 130, tablet: 130, desktop: 130 },
      imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
  }, 
  frase6: { 
    text: "l",
    x: { mobile: 54, tablet: 54, desktop: 54}, 
    y: { mobile:  90.5 , tablet:  90.5 , desktop: 90.5 },
    fontSize: { mobile: 210, tablet: 210, desktop: 210},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
};


function Chica() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/Chica/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chica;