import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: "PROTECCIÓN DE LUZ INFRARROJA,",
        x: { mobile: 52, tablet: 52, desktop: 52 }, 
        y: { mobile: 78, tablet: 78, desktop: 78 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase2: { 
        text: "PARA UNA", 
        x: { mobile: 52, tablet: 52, desktop: 52 }, 
        y: { mobile: 90, tablet: 90, desktop: 90 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase3: { 
        text: <strong>MIRADA SIEMPRE JOVEN</strong>,
        x: { mobile: 72, tablet: 72, desktop: 72 }, 
        y: { mobile: 90, tablet: 90, desktop: 90 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    }
};


function Chica() {
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Chica/Chica.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chica;
