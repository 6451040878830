import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/Owl/Mas/LenDer.webp',
        x: { mobile: 60, tablet: 60, desktop: 60 },
        y: { mobile: 33, tablet: 33, desktop:  33 }, 
        imgWidth: { mobile: 690, tablet: 690, desktop: 690 },
        imgHeight: { mobile: 480, tablet: 480, desktop: 480 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
        imgSrc: '/Owl/Mas/LenIzq.webp',
        x: { mobile: 104, tablet: 104, desktop: 104 },
        y: { mobile: 27, tablet: 27, desktop:  27 }, 
        imgWidth: { mobile: 700, tablet: 700, desktop: 700 },
        imgHeight: { mobile: 540, tablet: 540, desktop: 540 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase3: { 
        text: "LENTE TRADICIONAL", 
        x: { mobile: 69, tablet: 69, desktop: 69}, 
        y: { mobile: 28, tablet: 28, desktop:28 },
        fontSize: { mobile: 45, tablet: 45, desktop: 45},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]',
                tablet: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]',
                desktop: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]'
            }
        }
    },
    frase4: { 
        text: "LENTE", 
        x: { mobile: 115, tablet: 115, desktop: 115}, 
        y: { mobile: 28, tablet: 28, desktop:28 },
        fontSize: { mobile: 45, tablet: 45, desktop: 45},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]',
                tablet: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]',
                desktop: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]'
            }
        }
    },
    frase5: { 
        imgSrc: '/Owl/Mas/owlHdIcono.webp',
        x: { mobile: 123, tablet: 123, desktop: 123 },
        y: { mobile: 23, tablet: 23, desktop:  23 }, 
        imgWidth: { mobile: 140, tablet: 140, desktop: 140 },
        imgHeight: { mobile: 85, tablet: 85, desktop: 85 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    }, 
    
    frase6: { 
        text: "VISIÓN MÁS NÍTIDA Y PRECISA", 
        x: { mobile: 70, tablet: 70, desktop: 70}, 
        y: { mobile: 12, tablet: 12, desktop:12 },
        fontSize: { mobile: 93, tablet: 93, desktop: 93},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]',
                tablet: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]',
                desktop: 'font-now-bold text-white [text-shadow:0_0_10px_rgba(255,255,255,0.8)]'
            }
        }
    },
    frase7: { 
        text: "Un lente tradicional sólo enfoca la parte central del lente y en los costados se ve casi borroso,", 
        x: { mobile: 58, tablet: 58, desktop: 58}, 
        y: { mobile: 87, tablet: 87, desktop: 87 },
        fontSize: { mobile: 43, tablet: 43, desktop: 43},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-gray-500',
                tablet: 'font-now-bold text-gray-500',
                desktop: 'font-now-bold text-gray-500'
            }
        }
    },
    frase8: { 
        text: "sin embargo el lente digital enfoca toda superficie del lente, mires a donde mires.",
        x: { mobile: 64, tablet: 64, desktop: 64}, 
        y: { mobile: 92, tablet: 92, desktop:92},
        fontSize: { mobile: 43, tablet: 43, desktop: 43},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-gray-500',
                tablet: 'font-now-bold text-gray-500',
                desktop: 'font-now-bold text-gray-500'
            }
        }
    },
};


function Mas() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/Mas/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Mas;
