import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: {
      text: "RAYOS UV", 
      x: { mobile: 120, tablet: 120, desktop: 120}, 
      y: { mobile: 34, tablet: 34, desktop:  34}, 
      fontSize: { mobile: 100, tablet: 100, desktop: 100},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase3: {
      text: "- Envejecimiento del ojo", 
      x: { mobile: 118, tablet: 118, desktop: 118}, 
      y: { mobile: 45, tablet: 45, desktop:  45}, 
      fontSize: { mobile: 50, tablet: 50, desktop: 50},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase4: {
      text: "- Aparición de las cataratas", 
      style: "font-now-bold text-white",
      x: { mobile: 118, tablet: 118, desktop: 118}, 
      y: { mobile: 50, tablet: 50, desktop:  50}, 
      fontSize: { mobile: 50, tablet: 50, desktop: 50},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase5: {
      text: "LUZ AZUL", 
      x: { mobile: 58, tablet: 58, desktop: 58}, 
      y: { mobile: 74, tablet: 74, desktop:  74}, 
      fontSize: { mobile: 100, tablet: 100, desktop: 100},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase6: {
      text: "- Daño a las células de la retina",
      x: { mobile: 57, tablet: 57, desktop: 57}, 
      y: { mobile: 85, tablet: 85, desktop:  85}, 
      fontSize: { mobile: 50, tablet: 50, desktop: 50},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase7: {
      text: "- Degeneración macular", 
      x: { mobile: 57, tablet: 57, desktop: 57}, 
      y: { mobile: 90, tablet: 90, desktop:  90}, 
      fontSize: { mobile: 50, tablet: 50, desktop: 50},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
};


function Ojo() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/Ojo/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Ojo;