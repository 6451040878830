import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';
import { table } from 'framer-motion/client';

const frases = {
  frase1: { 
    text: "TALLADO TRADICIONAL",
    x: { mobile: 105, tablet: 105, desktop: 62}, 
    y: { mobile:  17 , tablet:  17 , desktop: 8 },
    fontSize: { mobile: 95, tablet: 95, desktop: 47},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase2: { 
    text: "TALLADO DIGITAL",
    x: { mobile: 105, tablet: 105, desktop: 115}, 
    y: { mobile:  17 , tablet:  17 , desktop: 8 },
    fontSize: { mobile: 95, tablet: 95, desktop: 47},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase3: { 
    text: "l",
    x: { mobile: 105, tablet: 105, desktop: 61}, 
    y: { mobile:  17 , tablet:  17 , desktop: 8.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-[#DBFE00]', // Color personalizado en Tailwind
        tablet: 'font-now text-[#DBFE00]',
        desktop: 'font-now text-[#DBFE00]'
      }
    }
  },
  frase4: { 
    text: "l",
    x: { mobile: 105, tablet: 105, desktop: 114}, 
    y: { mobile:  17 , tablet:  17 , desktop: 8.5 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-[#DBFE00]',
        tablet: 'font-now text-[#DBFE00]',
        desktop: 'font-now text-[#DBFE00]'
      }
    }
  },  
  frase5: { 
    imgSrc: '/Progresivos/Cuarto/un.webp',
    x: { mobile: 105, tablet: 105, desktop: 60},
    y: { mobile: 30, tablet: 30, desktop:  15 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 500 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 740 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase6: { 
    imgSrc: '/Progresivos/Cuarto/do.webp',
    x: { mobile: 105, tablet: 105, desktop: 110},
    y: { mobile: 30, tablet: 30, desktop:  15 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 500 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 739 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
  frase7: { 
    imgSrc: '/Progresivos/Cuarto/Fle.webp',
    x: { mobile: 105, tablet: 105, desktop: 96},
    y: { mobile: 30, tablet: 30, desktop:  48 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 100 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 70 },
    opacity: 100,
    customStyles: {
      image: {
        mobile: '',
        tablet: '',
        desktop: ''
      }
    }
  },
};


export const CuartoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlanco.webp"
        isBackground={true}
      />
    </div>
  );
}
