import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: "Test Clinicos", 
        x: { mobile: 70, tablet: 70, desktop: 70 }, 
        y: { mobile: 8, tablet: 8, desktop: 8 },
        fontSize: { mobile: 30, tablet: 30, desktop: 30 },
        opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now text-gray-600',
            tablet: 'font-now text-gray-600',
            desktop: 'font-now text-gray-600'
          }
        }
    },
    frase2: { 
        text: "PERFORMANCE VISUAL", 
        style: "font-now text-gray-600",
        x: { mobile: 62, tablet: 62, desktop: 62 }, 
        y: { mobile: 14, tablet: 14, desktop: 14 }, 
        fontSize: { mobile: 50, tablet: 50, desktop: 50},
        opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now text-gray-600',
            tablet: 'font-now text-gray-600',
            desktop: 'font-now text-gray-600'
          }
        }
    },
    frase3: { 
      imgSrc: '/AilensComponents/Chico/100.webp',
      x: { mobile: 68, tablet: 68, desktop: 68 },
      y: { mobile: 22, tablet: 22, desktop: 22 }, 
      imgWidth: { mobile: 225, tablet: 225, desktop: 225 },
      imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
      opacity: 100,
      customStyles: {
        image: {
          mobile: '',
          desktop: ''
        }
      }
    },
    frase4: { 
        text: "Aceptaciones en todas las", 
        x: { mobile: 62, tablet: 62, desktop: 62 }, 
        y: { mobile: 43, tablet: 43, desktop: 43 }, 
        fontSize: { mobile: 40, tablet: 40, desktop: 40 },
        opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now-bold text-gray-600',
            tablet: 'font-now-bold text-gray-600',
            desktop: 'font-now-bold text-gray-600'
          }
        }
    },
    frase5: { 
        text: "pruebas clinicas", 
        x: { mobile: 66, tablet: 66, desktop: 66 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 },
        fontSize: { mobile: 40, tablet: 40, desktop: 40},
        opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now-bold text-gray-600',
            tablet: 'font-now-bold text-gray-600',
            desktop: 'font-now-bold text-gray-600'
          }
        }
    },
    frase6: { 
      imgSrc: '/AilensComponents/Chico/88.webp',
      x: { mobile: 68, tablet: 68, desktop: 68 },
      y: { mobile: 55, tablet: 55, desktop: 55 }, 
      imgWidth: { mobile: 225, tablet: 225, desktop: 225 },
      imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
      opacity: 100,
      customStyles: {
        image: {
          mobile: '',
          desktop: ''
        }
      }
    },
    frase7: { 
        text: "Redujeron el tiempo de", 
        x: { mobile: 64, tablet: 64, desktop: 64 }, 
        y: { mobile: 77, tablet: 778, desktop: 77 }, 
        fontSize: { mobile:40, tablet: 40, desktop: 40 },
        opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now-bold text-gray-600',
            tablet: 'font-now-bold text-gray-600',
            desktop: 'font-now-bold text-gray-600'
          }
        }
    },
    frase8: { 
      text: " adaptación respecto a", 
      x: { mobile: 64, tablet: 64, desktop: 64 }, 
      y: { mobile: 83, tablet: 83, desktop: 83 }, 
      fontSize: { mobile: 40, tablet: 40, desktop: 40 },
      opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now-bold text-gray-600',
            tablet: 'font-now-bold text-gray-600',
            desktop: 'font-now-bold text-gray-600'
          }
        }
    },
    frase9: { 
      text: "otros lentes progresivos", 
      x: { mobile: 64, tablet: 64, desktop: 64 }, 
      y: { mobile: 89, tablet: 89, desktop: 89 }, 
      fontSize: { mobile: 40, tablet: 40, desktop: 40 },
      opacity: 100,
        customStyles: {
          mobile: '',
          tablet: '',
          desktop: '',
          text: {
            mobile: 'font-now-bold text-gray-600',
            tablet: 'font-now-bold text-gray-600',
            desktop: 'font-now-bold text-gray-600'
          }
        }
    },
  
    
};


function Chico() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/AiLensComponents/Chico/Senor.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chico;
