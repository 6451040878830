import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "CARACTERÍSTICAS",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 20 },
    fontSize: { mobile: 95, tablet: 95, desktop: 117},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-bold text-white',
        tablet: 'font-now-bold text-white',
        desktop: 'font-now-bold text-white'
      }
    }
  },
  frase2: { 
    text: "Es un nuevo concepto en Progresivos",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 40 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase3: { 
    text: "desarrollado por OPTICENTRO, gracias",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 47 },
    fontSize: { mobile: 95, tablet: 95, desktop: 56},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase4: { 
    text: "a la vasta experiencia y trayectoria,",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 53 },
    fontSize: { mobile: 95, tablet: 95, desktop: 56},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase5: { 
    text: "con la calidad y tecnología de punta",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 59 },
    fontSize: { mobile: 95, tablet: 95, desktop: 56},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase6: { 
    text: "de nuestro laboratorio de última",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 65 },
    fontSize: { mobile: 95, tablet: 95, desktop: 57},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase7: { 
    text: "generación.",
    x: { mobile: 105, tablet: 105, desktop: 52}, 
    y: { mobile:  17 , tablet:  17 , desktop: 70 },
    fontSize: { mobile: 95, tablet: 95, desktop: 57},
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
};


export const TerceroProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/Tercero/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}
