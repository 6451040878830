import React from 'react';
//Animacion
import AnimatedSection from '../shared/hooks/useAnimation';

//ParteInicial
import Buho from './pages/Buho.owlHd';
import Vision from './pages/Vision.owlHd';
import Mas from './pages/Mas.owlHd';
import Izq from './pages/Izq.owlHd';
import Ranita from './pages/Ranita.owlHd';
import Chica from './pages/Chica.owlHd';
import OwlHd from './pages/OwlHd.owlHd';
import Sucursales from '../Inicio/pages/Sucursales.inicio';



function OwlHD() {
    return (
        <div className="flex flex-col" style={{ overflowY: 'hidden', margin: 0, padding: 0 }}>
            <AnimatedSection>
                <div className="flex justify-center items-center h-screen" style={{ margin: 0, padding: 0 }}>
                    <div className="w-1/3 md:w-1/3 lg:w-1/6">
                        <img
                            src="/Owl/owlLogo.webp"
                            alt="Logo Stop Age"
                            className="w-full h-auto object-contain"
                        />
                    </div>
                </div>
            </AnimatedSection>
            {[Buho, Vision, Mas, Izq, Ranita,Chica, OwlHd, Sucursales].map((Component, index) => (
                <AnimatedSection key={index}>
                    <div className="relative flex items-center justify-center"  style={{ margin: 0, padding: 0 }}>
                        <Component />
                    </div>
                </AnimatedSection>
            ))}
        </div>
    );
}
export default OwlHD;


