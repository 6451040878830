import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: { 
        imgSrc: '/BlueClarity/Lentes1/Lente.webp',
        x: { mobile: 65, tablet: 65, desktop: 65},
        y: { mobile: 10, tablet: 10, desktop:  10 }, 
        imgWidth: { mobile: 830, tablet: 830, desktop: 830 },
        imgHeight: { mobile: 840, tablet: 840, desktop: 840 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase3: { 
        imgSrc: '/BlueClarity/Lentes2/FlechaBaj.webp',
        x: { mobile: 108, tablet: 108, desktop: 108},
        y: { mobile: 33, tablet: 33, desktop:  33 }, 
        imgWidth: { mobile: 160, tablet: 160, desktop: 160 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase4: { 
        imgSrc: '/BlueClarity/Lentes2/FlechaBaj.webp',
        x: { mobile: 100, tablet: 100, desktop: 100},
        y: { mobile: 38, tablet: 38, desktop:  38 }, 
        imgWidth: { mobile: 160, tablet: 160, desktop: 160 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase5: { 
        imgSrc: '/BlueClarity/Lentes2/FlechaBaj.webp',
        x: { mobile: 92, tablet: 92, desktop: 92},
        y: { mobile: 43, tablet: 43, desktop:  43 }, 
        imgWidth: { mobile: 160, tablet: 160, desktop: 160 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase6: { 
        imgSrc: '/BlueClarity/Lentes2/FlechaArr.webp',
        x: { mobile: 100, tablet: 100, desktop: 100},
        y: { mobile: 24, tablet: 24, desktop:  24 }, 
        imgWidth: { mobile: 170, tablet: 170, desktop: 170 },
        imgHeight: { mobile: 100, tablet: 100, desktop: 100 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase7: { 
        imgSrc: '/BlueClarity/Lentes2/FlechaArr.webp',
        x: { mobile: 90, tablet: 90, desktop: 90},
        y: { mobile: 34, tablet: 34, desktop:  34 }, 
        imgWidth: { mobile: 170, tablet: 170, desktop: 170 },
        imgHeight: { mobile: 100, tablet: 100, desktop: 100 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase8: { 
        imgSrc: '/BlueClarity/Lentes2/Azul.webp',
        x: { mobile: 110, tablet: 110, desktop: 110},
        y: { mobile: 47, tablet: 47, desktop:  47 }, 
        imgWidth: { mobile: 740, tablet: 740, desktop: 740 },
        imgHeight: { mobile: 430, tablet: 430, desktop: 430 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase9: {
        text: "LUZ AZUL VIOLETA UVA UVB", 
        x: { mobile: 112, tablet: 112, desktop: 112}, 
        y: { mobile: 52, tablet: 52, desktop:  52}, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase10: {
        text: "Blu Clarity bloquea la luz azul", 
        x: { mobile: 114, tablet: 114, desktop: 114}, 
        y: { mobile: 60, tablet: 60, desktop:  60}, 
        fontSize: { mobile: 50, tablet: 50, desktop: 50},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase11: {
        text: "violeta nociva que produce fatiga", 
        x: { mobile: 114, tablet: 114, desktop: 114}, 
        y: { mobile: 66, tablet: 66, desktop: 66}, 
        fontSize: { mobile: 45, tablet: 45, desktop: 45},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase12: {
        text: "y estrés visual, visión borrosa,", 
        x: { mobile: 114, tablet: 114, desktop: 114}, 
        y: { mobile: 72, tablet: 72, desktop:  72}, 
        fontSize: { mobile: 45, tablet: 45, desktop: 45},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase13: {
        text: "insomnio y sequedad ocular entre", 
        x: { mobile: 114, tablet: 114, desktop: 114}, 
        y: { mobile: 78, tablet: 78, desktop:  78}, 
        fontSize: { mobile: 45, tablet: 45, desktop: 45},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase14: {
        text: "otros síntomas.", 
        x: { mobile: 114, tablet: 114, desktop: 114}, 
        y: { mobile: 84, tablet: 84, desktop:  84}, 
        fontSize: { mobile: 45, tablet: 45, desktop: 45},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },
    frase16: {
      text: "LUZ AZUL VIOLETA", 
      x: { mobile: 90, tablet: 90, desktop: 90}, 
      y: { mobile: 8, tablet: 8, desktop:  8}, 
      fontSize: { mobile: 90, tablet: 90, desktop: 90},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now text-white',
                tablet: 'font-now text-white',
                desktop: 'font-now text-white'
            }
        }
    },

};


function LentesDos() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/Lentes1/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default LentesDos;