import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "LA LUZ INFRARROJA", 
    x: { mobile: 115, tablet: 115, desktop: 115 }, 
    y: { mobile: 8, tablet: 8, desktop: 8}, 
    fontSize: { mobile: 75, tablet: 75, desktop: 75 },
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-thin text-white',
        tablet: 'font-now-thin text-white',
        desktop: 'font-now-thin text-white'
      }
    }
  },
  frase2: { 
    text: <strong>NO ES DAÑINA</strong>,
    x: { mobile: 124, tablet: 124, desktop: 124 }, 
    y: { mobile: 16, tablet: 16, desktop: 16 }, 
    fontSize: { mobile: 75, tablet: 75 , desktop: 75 },
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now text-white',
        tablet: 'font-now text-white',
        desktop: 'font-now text-white'
      }
    }
  },
  frase3: { 
    text: "La luz infrarroja se utiliza en", 
    x: { mobile: 101, tablet: 101, desktop: 101 }, 
    y: { mobile: 42, tablet: 42, desktop: 42 }, 
    fontSize: { mobile: 75, tablet: 75, desktop: 75 },
    opacity: 100,
    customStyles: {
      mobile: '',
      tablet: '',
      desktop: '',
      text: {
        mobile: 'font-now-thin text-white',
        tablet: 'font-now-thin text-white',
        desktop: 'font-now-thin text-white'
      }
    }
  },
  frase4: { 
    text: "tratamientos terapéuticos para ", 
    style: "font-now-thin text-white",
    x: { mobile: 95, tablet: 95, desktop: 95 }, 
    y: { mobile: 51, tablet: 51, desktop: 51 }, 
    fontSize: { mobile: 75, tablet: 75, desktop: 75 }
  },
  frase5: { 
    text: "aliviar el dolor muscular, estimular", 
    style: "font-now-thin text-white",
    x: { mobile: 90, tablet: 90, desktop: 90 }, 
    y: { mobile: 61, tablet: 61, desktop: 61 }, 
    fontSize: { mobile: 75, tablet: 75, desktop: 75 }
  },
  frase6: { 
    text: "el colágeno y favorecer la", 
    style: "font-now-thin text-white",
    x: { mobile: 102, tablet: 102, desktop: 102 }, 
    y: { mobile: 71, tablet: 71, desktop: 71 }, 
    fontSize: { mobile: 75, tablet: 75, desktop: 75 }
  },
  frase7: { 
    text: "curación de lesiones.", 
    style: "font-now-thin text-white",
    x: { mobile: 112, tablet: 112, desktop: 112 },
    y: { mobile: 81, tablet: 81, desktop: 81 }, 
    fontSize: { mobile: 75, tablet: 75, desktop: 75}
  },
};

function LuzInfrarroja() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Espalda/Espalda.webp"
        isBackground={true}
      />
    </div>
  );
}

export default LuzInfrarroja;