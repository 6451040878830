import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase2: {
        text: "El uso frecuente de pantallas y exposición a luces ",
        x: { mobile: 95, tablet: 95, desktop: 95}, 
        y: { mobile: 60, tablet: 60, desktop:  60}, 
        fontSize: { mobile: 50, tablet: 50, desktop: 50},
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase3: {
      text: "led, aumenta el riesgo de sufrir daños en la salud", 
      x: { mobile: 95, tablet: 95, desktop: 95}, 
      y: { mobile: 65, tablet: 65, desktop:  65}, 
      fontSize: { mobile: 50, tablet: 50, desktop: 50},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase4: {
      text: "visual y puede provocar:", 
      x: { mobile: 110, tablet: 110, desktop: 110}, 
      y: { mobile: 70, tablet: 70, desktop:  70}, 
      fontSize: { mobile: 50, tablet: 50, desktop: 50},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase5: { 
      imgSrc: '/BlueClarity/ChicaTriste/un.webp',
      x: { mobile: 95, tablet: 95, desktop: 95},
      y: { mobile: 74.5, tablet: 74.5, desktop:  74.5}, 
      imgWidth: { mobile: 220, tablet: 220, desktop: 220 },
      imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase6: { 
      imgSrc: '/BlueClarity/ChicaTriste/do.webp',
      x: { mobile: 110, tablet: 110, desktop: 110},
      y: { mobile: 74, tablet: 74, desktop:  74 }, 
      imgWidth: { mobile: 160, tablet: 160, desktop: 160 },
      imgHeight: { mobile: 160, tablet: 160, desktop: 160 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase7: { 
      imgSrc: '/BlueClarity/ChicaTriste/tr.webp',
      x: { mobile: 122, tablet: 122, desktop: 122},
      y: { mobile: 75, tablet: 75, desktop:  75 }, 
      imgWidth: { mobile: 190, tablet: 190, desktop: 190 },
      imgHeight: { mobile: 150, tablet: 150, desktop: 150 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase8: { 
      imgSrc: '/BlueClarity/ChicaTriste/cu.webp',
      x: { mobile: 135, tablet: 135, desktop: 135},
      y: { mobile: 75, tablet: 75, desktop:  75 }, 
      imgWidth: { mobile: 190, tablet: 190, desktop: 190 },
      imgHeight: { mobile: 150, tablet: 150, desktop: 150 },
      opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
};


function ChicaTriste() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/ChicaTriste/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default ChicaTriste;