import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
      text: "LENTES TRADICIONAL", 
      x: { mobile: 66, tablet: 66, desktop: 66 }, 
      y: { mobile: 20, tablet: 20, desktop: 20 }, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-gray-600',
                tablet: 'font-now-bold text-gray-600',
                desktop: 'font-now-bold text-gray-600'
            }
        }
    },
    frase2: { 
        imgSrc: '/Owl/Izq/lenteCua.webp',
        x: { mobile: 60, tablet: 60, desktop: 60 },
        y: { mobile: 28, tablet: 28, desktop:  28 }, 
        imgWidth: { mobile: 640, tablet: 640, desktop: 640 },
        imgHeight: { mobile: 430, tablet: 430, desktop: 430 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase3: { 
      text: "LENTE", 
      x: { mobile: 115, tablet: 115, desktop: 115 }, 
      y: { mobile: 22, tablet: 22, desktop: 22 }, 
      fontSize: { mobile: 45, tablet: 45, desktop: 45},
      opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-bold text-white',
                tablet: 'font-now-bold text-white',
                desktop: 'font-now-bold text-white'
            }
        }
    },
    frase4: { 
        imgSrc: '/Owl/Izq/lenteNum.webp',
        x: { mobile: 105, tablet: 105, desktop:105 },
        y: { mobile: 25, tablet: 25, desktop:  25 }, 
        imgWidth: { mobile: 630, tablet: 630, desktop: 630 },
        imgHeight: { mobile: 480, tablet: 480, desktop: 480 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    frase5: { 
        imgSrc: '/Owl/Izq/owlHdIcono.webp',
        x: { mobile: 122, tablet: 122, desktop:122 },
        y: { mobile: 17, tablet: 17, desktop:  17 }, 
        imgWidth: { mobile: 140, tablet: 140, desktop: 140 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
        opacity: 100,
        customStyles: {
            image: {
            mobile: '',
            tablet: '',
            desktop: '',
            }
        }
    },
    
};


function Izq() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/Izq/iconic.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Izq;
