import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: "¡LO QUE NO SABIAMOS!", 
        x: { mobile: 51, tablet: 51, desktop: 51 }, 
        y: { mobile: 9, tablet: 9, desktop: 9 }, 
        fontSize: { mobile: 185, tablet: 185, desktop: 185 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase2: { 
        text: "ESTAMOS SOBREEXPUESTOS A LA LUZ INFRARROJA", 
        x: { mobile: 53, tablet: 53, desktop: 53 }, 
        y: { mobile: 24, tablet: 24, desktop: 24 }, 
        fontSize: { mobile: 85, tablet: 85, desktop: 85 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase3: { 
        text: "SOL", 
        x: { mobile: 58, tablet: 58, desktop: 58 }, 
        y: { mobile: 93, tablet: 91, desktop: 91 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase4: { 
        text: "HORNALLAS", 
        x: { mobile: 76, tablet: 76, desktop: 76 }, 
        y: { mobile: 90, tablet: 90, desktop: 90 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase5: { 
        text: "DE COCINA", 
        x: { mobile: 76, tablet: 76, desktop: 76 }, 
        y: { mobile: 96, tablet: 96, desktop: 96 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase6: { 
        text: "PARRILLAS", 
        x: { mobile: 105, tablet: 105, desktop: 105 }, 
        y: { mobile: 93, tablet: 93.5, desktop: 93.5 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    },
    frase7: { 
        text: "ESTUFAS", 
        x: { mobile: 132, tablet: 132, desktop: 132 }, 
        y: { mobile: 93, tablet: 93.5, desktop: 93.5 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 },
        opacity: 100,
        customStyles: {
            mobile: '',
            tablet: '',
            desktop: '',
            text: {
                mobile: 'font-now-thin text-white',
                tablet: 'font-now-thin text-white',
                desktop: 'font-now-thin text-white'
            }
        }
    }
};


function Estufa() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Hornalla/Hornalla.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Estufa;
